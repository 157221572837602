import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'hidden': _ctx.hidden}, "pagination-container"]),
    style: {"float":"right"}
  }, [
    _createVNode(_component_a_pagination, _mergeProps({
      current: _ctx.currentPage,
      "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
      pageSize: _ctx.pageSize,
      "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageSize) = $event)),
      total: _ctx.total
    }, _ctx.$attrs, {
      pageSizes: _ctx.pageSizes,
      showTotal: _ctx.showTotal,
      "show-size-changer": _ctx.showSizeChanger,
      onShowSizeChange: _ctx.handleSizeChange,
      onChange: _ctx.handleCurrentChange
    }), null, 16, ["current", "pageSize", "total", "pageSizes", "showTotal", "show-size-changer", "onShowSizeChange", "onChange"])
  ], 2))
}