
import { customRef, ref,defineComponent } from "vue";
import moment from "moment-timezone";
import { LogDetailModel } from '@/api-client/modal';
import Pagination from '@/components/Pagination/index.vue'

export default defineComponent({
  components: {
    Pagination
  },
  props: {
    logDetails: {
      type: LogDetailModel,
      default: () => new LogDetailModel()
    },
    isPagination: {
      type: Boolean,
      default: () => true
    },
    pageIndex: {
      type: Number,
      default: () => 1
    },
    pageSize: {
      type: Number,
      default: () => 10
    }
  },
  setup(props,context) {
    const timeZoneMoment = moment;

    const showLogModal = ref<boolean>(false);
    const logModalLoading = ref<boolean>(false);
    const pageIndex = customRef<Number>((track, trigger) => {
      return {
        get() {
          return props.pageIndex;
        },
        set(newValue: Number) {
          context.emit("update:pageIndex", newValue);
        },
      };
    })
    const pageSize = customRef<Number>((track, trigger) => {
       return {
        get() {
          return props.pageSize;
        },
        set(newValue: Number) {
          context.emit("update:pageSize", newValue);
        },
      };
    })

    function getLogList() {
      logModalLoading.value = true
      try {
        context.emit('getLogList')
      }
      finally {
        logModalLoading.value = false
      }
    }

    return { timeZoneMoment, showLogModal, logModalLoading, pageIndex, pageSize, getLogList };
  },
})
