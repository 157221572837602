
import { customRef, defineComponent, PropType, ref } from "vue";
import { prop, setup } from "vue-class-component";

export default defineComponent({
  props: {
    total: {
      type: Number,
      require: true,
    },
    page: {
      type: Number,
      default: 1,
      require: true,
    },
    limit: {
      type: Number,
      default: 10,
      require: true,
    },
    pageSizes: {
      type: Array as PropType<number[]>,
      default: () => [10, 20, 30, 50],
    },
    showTotal: {
      type: Function,
      default: (total: Number) => `Total ${total} items`,
    },
    showSizeChanger: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const currentPage = customRef<Number>((track, trigger) => {
      return {
        get() {
          return props.page;
        },
        set(newValue: Number) {
          context.emit("update:page", newValue);
        },
      };
    });
    const pageSize = customRef<Number>((track, trigger) => {
      return {
        get() {
          return props.limit;
        },
        set(newValue: Number) {
          context.emit("update:limit", newValue);
        },
      };
    });
    function handleSizeChange(value: number) {
      currentPage.value = 1;
      context.emit("pagination", { page: currentPage, limit: value });
    }

    function handleCurrentChange(value: number) {
      context.emit("pagination", { page: value, limit: pageSize });
    }

    return { currentPage, pageSize, handleSizeChange, handleCurrentChange };
  },
});
